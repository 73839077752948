import React from 'react'
import styled from 'styled-components'
import { Section } from './Footer'
import { WHITE } from '../../assets/colors'
/** ASSETS */
import contactData from '../../assets/data/contact.json'

const ContactLine = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 769px) {
    justify-content: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 20px;

    @media (min-width: 769px) {
      margin-bottom: 30px;
    }
  }
`

const ContactInfo = styled.div`
  cursor: pointer;
  line-height: 2.4rem;
  font-weight: 500;
  text-align: left;
`

const Icon = styled.i`
  color: ${WHITE};
  font-size: 20px;
  margin-right: 20px;
  text-align: center;
  width: 20px;
`

const LocationIcon = styled(Icon)`
  margin-top: 4px;
`

const Contact: React.FC = () => {
  const Container = styled(Section)`
    @media (min-width: 769px) {
      align-items: flex-end;
    }
  `

  const onNavigateToGoogleMaps = () => {
    window.open(
      'https://www.google.com/maps/dir//Coifflo,+Margote+20,+9260+Wichelen/@51.0095393,3.9708986,17.18z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c3993ef69a9f77:0x676e1af8d69a55f!2m2!1d3.9707315!2d51.0079701!3e0',
      '_blank'
    )
  }

  return (
    <Container>
      <div>
        <h1>Contact</h1>
        <ContactLine>
          <Icon className="fas fa-phone-alt" />
          <strong>
            <a href={`tel:${contactData.tel}`}>{contactData.tel}</a>
          </strong>
        </ContactLine>
        <ContactLine>
          <Icon className="fas fa-envelope" />
          <a href={`mailto:${contactData.email}`}>
            <strong>{contactData.email}</strong>
          </a>
        </ContactLine>
        <ContactLine>
          <LocationIcon className="fas fa-map-marker-alt" />
          <ContactInfo onClick={onNavigateToGoogleMaps}>
            {contactData.addressStreet},
            <br />
            {contactData.addressCity}
          </ContactInfo>
        </ContactLine>
      </div>
    </Container>
  )
}

export default Contact
