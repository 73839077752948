import React, { useEffect } from 'react'

const ScrollToTop = () => {
  useEffect(() => {
    const onTop = () => {
      window.scrollTo(0, 0)
    }

    onTop()
  }, [])

  return <></>
}

export default ScrollToTop
