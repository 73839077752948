import React from 'react'
import WorkingDay from './WorkingDay'
import ContentTitle from '../ContentTitle'
/** ASSETS */
import openHoursData from '../../assets/data/openingsuren.json'

export enum Usage {
  footer = 'footer',
  content = 'content'
}

type Props = {
  usage?: Usage
  isTablet?: boolean
}

const WorkingHours: React.FC<Props> = ({ usage = Usage.footer, isTablet = false }) => (
  <>
    {usage === Usage.footer ? <h1>Openingsuren</h1> : <ContentTitle title="Openingsuren" />}
    <WorkingDay day={isTablet ? 'Ma' : 'Maandag'} openHours={openHoursData.monday} usage={usage} />
    <WorkingDay day={isTablet ? 'Di' : 'Dinsdag'} openHours={openHoursData.tuesday} usage={usage} />
    <WorkingDay day={isTablet ? 'Woe' : 'Woensdag'} openHours={openHoursData.wednesday} usage={usage} />
    <WorkingDay day={isTablet ? 'Do' : 'Donderdag'} openHours={openHoursData.thursday} usage={usage} />
    <WorkingDay day={isTablet ? 'Vr' : 'Vrijdag'} openHours={openHoursData.friday} usage={usage} />
    <WorkingDay day={isTablet ? 'Zat' : 'Zaterdag'} openHours={openHoursData.saturday} usage={usage} />
    <WorkingDay day={isTablet ? 'Zon' : 'Zondag'} openHours={openHoursData.sunday} usage={usage} />
  </>
)

export default React.memo<Props>(WorkingHours)
