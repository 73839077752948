import React from 'react'
import styled from 'styled-components'
import { PRIMARY, PRIMARY_MINUS_70, WHITE } from '../assets/colors'

export enum Size {
  small = 'small',
  medium = 'medium'
}

type Props = {
  className?: string
  children: React.ReactNode
  size: Size
}

type ContainerProps = {
  size: Size
}

const Container = styled.button<ContainerProps>`
  background-color: ${PRIMARY};
  border-radius: 33px;
  color: ${WHITE};
  font-weight: 500;
  padding: ${({ size }) => (size === Size.small ? '16px 28px' : '20px 40px')};
  transition: background-color 100ms linear;

  &:hover {
    background-color: ${PRIMARY_MINUS_70};
  }
`

const Button: React.FC<Props> = ({ className, children, size }) => {
  const onNavigateToBooking = () => window.open('https://booking.optios.net/10397/menu', '_self')

  return (
    <Container className={className} size={size} onClick={onNavigateToBooking}>
      {children}
    </Container>
  )
}

export default React.memo<Props>(Button)
