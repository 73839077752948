import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import Me from './Me'
import Banner from '../../components/Banner'
import ImageFrame from '../../components/ImageFrame'
import ScrollToTop from '../../components/ScrollToTop'
import ContentTitle from '../../components/ContentTitle'
import { ResponsiveProps, Wrapper, WrapperReverse } from '../Contact'
/** ASSETS */
import bannerImg from '../../assets/images/banner.jpeg'
import aboutFirstImg from '../../assets/images/about_first.jpg'
import aboutSecImg from '../../assets/images/about_second.jpeg'
import partnerImg from '../../assets/images/partner-products.jpeg'

type FrameProps = {
  oneFrame: boolean
}

const Container = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const ContentWrapper = styled.div<ResponsiveProps>`
  ${({ isDesktop }) => isDesktop && 'align-self: center'};
`

export const TwoFrames = styled.div<FrameProps>`
  display: flex;
  column-gap: 20px;
  ${({ oneFrame }) => oneFrame && 'align-self: center'};
  ${({ oneFrame }) => oneFrame && 'max-width: 375px'};
`

export const FrameOne = styled(ImageFrame)<FrameProps>`
  ${({ oneFrame }) => !oneFrame && 'margin-top: 40px'};
`

export const FrameTwo = styled(ImageFrame)`
  margin-bottom: 40px;
`

const About: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 540px)').matches)
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 1076px)').matches)

  useEffect(() => {
    window.matchMedia('(max-width: 540px)').addEventListener('change', (e: any) => setIsMobile(e.matches))
    window.matchMedia('(min-width: 1076px)').addEventListener('change', (e: any) => setIsDesktop(e.matches))
  }, [])

  return (
    <>
      <ScrollToTop />
      <Container>
        <Banner title="Over ons" img={bannerImg} />
        <Wrapper isDesktop={isDesktop} className="margin-b-20">
          <Me isDesktop={isDesktop} />
          <TwoFrames oneFrame={isMobile}>
            <FrameOne img={aboutFirstImg} oneFrame={isMobile} />
            {!isMobile && <FrameTwo img={aboutSecImg} />}
          </TwoFrames>
        </Wrapper>
        <WrapperReverse isDesktop={isDesktop}>
          <ImageFrame img={partnerImg} />
          <ContentWrapper isDesktop={isDesktop}>
            <ContentTitle title="NAK Hair" />
            <div>
              <span className="margin-b-15">
                NAK HAIR is een op en top Australisch bedrijf, volledig in Australische handen en opgericht 'down under'
                met de typische nuchtere mentaliteit van daar. De productlijnen bevatten natuurlijke veganistische
                ingrediënten, luxueuze plantaardige producten en pure essentiële oliën, voor heerlijke haarrituelen met
                respect voor de delicate aard van het haar en de huid.
              </span>
              <span>
                TEGEN DIERLIJKE TESTEN - We blijven ons inzetten voor het gebruik van veilige, bewezen ingrediënten,
                niet getest op dieren. We voldoen aan de Europese wet, die het testen van cosmetische ingrediënten en
                producten op dieren verbiedt. We zetten positieve stappen voor het mileu, te beginnen met recyclebare
                verpakkingen, gecertificeerde inkten, papier en materialen. NAK-haarproducten bevatten sulfaatvrije en
                parabenenvrije ingrediëntentechnologie en bieden een milde, zachte reiniging voor haar en hoofdhuid.
              </span>
            </div>
          </ContentWrapper>
        </WrapperReverse>
      </Container>
    </>
  )
}

export default About
