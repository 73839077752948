import styled from 'styled-components'
import StarRatings from 'react-star-ratings'
import React, { useEffect, useState } from 'react'
import { PRIMARY } from '../assets/colors'
/** ASSETS */
import reviewsData from '../assets/data/reviews.json'

type InnerProps = {
  index: number
}

type IndicatorProps = {
  active: boolean
}

const Container = styled.div`
  overflow: hidden;
  position: relative;
`

const Inner = styled.div<InnerProps>`
  margin-bottom: 30px;
  transform: ${({ index }) => `translateX(-${index * 100}%)`};
  transition: transform 0.3s;
  white-space: nowrap;
  z-index: 1;
`

const Item = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  min-width: 0;
  text-align: center;
  width: 100%;
  white-space: normal;
`

const Indicators = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;

  & > button {
    margin: 5px;
  }
`

const Indicator = styled.button<IndicatorProps>`
  background-color: ${({ active }) => active && PRIMARY};
  border-radius: 50%;
  border: 2px solid ${PRIMARY};
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin: 0 4px;
  transition: ease 1000ms;
  width: 15px;
  margin: 5px;
`

const Reviewer = styled.div`
  font-weight: 600;
  margin-top: 5px;
`

const Carousel: React.FC = () => {
  const [activeIdx, setActiveIdx] = useState<number>(0)
  const [paused, setPaused] = useState(false)

  const numItems = Object.values(reviewsData.data).length
  const svgIconPath =
    'M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'

  const updateIndex = (newIdx: number) => {
    if (newIdx < 0) {
      newIdx = numItems - 1
    } else if (newIdx >= numItems) {
      newIdx = 0
    }
    setActiveIdx(newIdx)
  }

  const onHover = () => setPaused(true)

  const onHoverOut = () => setPaused(false)

  const onChangeReview = (index: number) => () => updateIndex(index)

  const renderReviews = () =>
    Object.values(reviewsData.data).map((item: any, idx: number) => (
      <Item key={idx}>
        {item.review}
        <div className="margin-t-20">
          <StarRatings
            rating={item.rating}
            starRatedColor="#ffcc00"
            numberOfStars={5}
            name="rating"
            starSpacing="2px"
            starDimension="20px"
            svgIconPath={svgIconPath}
            svgIconViewBox="0 0 576 512"
          />
        </div>
        <Reviewer>{item.name}</Reviewer>
      </Item>
    ))

  const renderIndicators = () =>
    Object.values(reviewsData.data).map((_: any, idx: number) => (
      <Indicator active={idx === activeIdx} onClick={onChangeReview(idx)} />
    ))

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) updateIndex(activeIdx + 1)
    }, 3000)

    return () => {
      if (interval) clearInterval(interval)
    }
  })

  return (
    <Container onMouseEnter={onHover} onMouseLeave={onHoverOut}>
      <Inner index={activeIdx}>{renderReviews()}</Inner>
      <Indicators>{renderIndicators()}</Indicators>
    </Container>
  )
}

export default Carousel
