import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  img: string
  height: number
}

const Figure = styled.figure`
  display: flex;
  justify-content: center;
`

const Image = styled.img`
  width: auto;
`

const Logo: React.FC<Props> = ({ className, img, height }) => (
  <Figure className={className}>
    <Image height={height} src={img} />
  </Figure>
)

export default React.memo<Props>(Logo)
