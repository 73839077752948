import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Content from './components/Content'
import Footer from './components/footer/Footer'
import Header from './components/Header'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
`

const Main = styled.main`
  height: 100%;
  padding: 0 20px;
  width: 100%;

  @media (min-width: 769px) {
    padding: 0 40px;
  }

  @media (min-width: 1025px) {
    padding: 0 60px;
  }

  @media (min-width: 1441px) {
    padding: 0 120px;
  }
`

const App: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches)
  const [isTablet, setIsTablet] = useState(window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches)
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 1076px)').matches)

  const onToggleOpenMenu = () => setOpenMenu((open) => !open)

  useEffect(() => {
    window.matchMedia('(max-width: 768px)').addEventListener('change', (e: any) => setIsMobile(e.matches))
    window
      .matchMedia('(min-width: 769px) and (max-width: 1024px)')
      .addEventListener('change', (e: any) => setIsTablet(e.matches))
    window.matchMedia('(min-width: 1076px)').addEventListener('change', (e: any) => setIsDesktop(e.matches))
  }, [])

  return (
    <Container>
      <Main>
        <Header isMobile={isMobile} isDesktop={isDesktop} openMenu={openMenu} onToggleOpenMenu={onToggleOpenMenu} />
        <Content openMenu={openMenu}></Content>
      </Main>
      <Footer isMobile={isMobile} isTablet={isTablet} />
    </Container>
  )
}

export default App
