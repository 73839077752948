import axios from 'axios'
import styled from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import LoadingSkeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Feed from '../../components/Feed'
import Banner from '../../components/Banner'
import ScrollToTop from '../../components/ScrollToTop'
import ContentTitle from '../../components/ContentTitle'
import { PRIMARY } from '../../assets/colors'
/** ASSETS */
import bannerImg from '../../assets/images/banner.jpeg'

type FeedItemProps = {
  numRows: number
}

const Container = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: center;
`

const FeedItems = styled.div<FeedItemProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  & > div:nth-last-of-type(-n+2) {
    display: ${({ numRows }) => (numRows === 3 ? 'none' : 'block')};
  }
}
`

const Loading = styled.div`
  margin-bottom: 94px;

  > span {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
`

const MorePhotos = styled.div`
  color: ${PRIMARY};
  cursor: pointer;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin-bottom: 40px;
  margin-top: 30px;
  text-align: center;
`

const Gallery: React.FC = () => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [feed, setFeedData] = useState([])
  const [fetching, setFetching] = useState(true)
  const [fetchingFailed, setFetchingFailed] = useState(false)
  const [itemWidth, setItemWidth] = useState<number>(0)

  const onOpenInstagram = () => {
    window.open('https://www.instagram.com/coifflo/', '_blank')
  }

  const renderFeed = () =>
    feed.map((feed, idx: number) => <Feed key={`instaFeed${idx}`} feed={feed} itemWidth={itemWidth} />)

  const calculateNumRows = () => {
    if (window.matchMedia('(min-width: 1007px)').matches) return 4
    if (window.matchMedia('(min-width: 722px) and (max-width: 1006px)').matches) return 3
    if (window.matchMedia('(min-width: 440px) and (max-width: 721px)').matches) return 2
    return 1
  }

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController()

    const fetchInstagramPost = async () => {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink&limit=${40}&access_token=IGQVJVcWdmM1lVaVZAsUzI3TzJ3R2NDV1Y5S19BMHhPcGdZAajdmajUyTFBaMkdvZAURWQ0txbmlDYlpBWGN0Rm4xWjVJVi1IclhTVTQ3akh0bWtkVG9PRVRrdU01N2ZAZAb0h3Rjl1MHR2a3VlaWMtd0pDVgZDZD`
          )
          .then((resp) => {
            const data = resp.data.data
            if (!!data.length) {
              const feed = data
                .filter(
                  (post: any) =>
                    post.media_type !== 'VIDEO' &&
                    (post.caption.includes('#galerij') || post.caption.includes('#gallerij'))
                )
                .slice(0, 20)
              setFeedData(feed)
              setFetching(false)
            }
          })
      } catch (err) {
        console.log('error', err)
        setFetchingFailed(true)
      }
    }

    const calculateItemDimensions = () => {
      const numRows = calculateNumRows()
      const width = contentRef!.current!.offsetWidth - 20 - 20
      setItemWidth(width / numRows - 5)
    }

    // manually call the fecth function
    if (contentRef.current) {
      fetchInstagramPost()
      window.addEventListener('resize', calculateItemDimensions)
    }

    calculateItemDimensions()

    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort()
      window.removeEventListener('resize', calculateItemDimensions)
    }
  }, [])

  return (
    <>
      <ScrollToTop />
      <Container ref={contentRef}>
        <Banner title="Galerij" img={bannerImg} />
        <StyledContentTitle title="Recentste foto's" />
        {fetching || fetchingFailed ? (
          <Loading>
            <SkeletonTheme borderRadius={0} height={itemWidth} inline width={itemWidth}>
              <LoadingSkeleton className="flex-grow" count={20} />
            </SkeletonTheme>
          </Loading>
        ) : (
          <FeedItems numRows={calculateNumRows()}>{renderFeed()}</FeedItems>
        )}
        {!fetching && !fetchingFailed && <MorePhotos onClick={onOpenInstagram}>Meer foto's bekijken</MorePhotos>}
      </Container>
    </>
  )
}

export default React.memo(Gallery)
