import React from 'react'
import styled from 'styled-components'
import { WHITE } from '../assets/colors'

type Props = {
  title: string
  img: string
}

type ImageProps = {
  img: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 200px;
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
  width: calc(100% + 40px);

  @media (min-width: 769px) {
    min-height: 250px;
    max-height: 250px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
  }

  @media (min-width: 1025px) {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
  }

  @media (min-width: 1441px) {
    margin-left: -120px;
    margin-right: -120px;
    width: calc(100% + 240px);
  }
`

const Image = styled.div<ImageProps>`
  background-image: url('${({ img }) => img}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
`

const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
`

const Title = styled.h1`
  color: ${WHITE};
  font-size: 4rem;
  font-weight: 700;
  line-height: 5rem;
`

const Banner: React.FC<Props> = ({ title, img }) => (
  <Container>
    <Image img={img} />
    <Overlay>
      <Title>{title}</Title>
    </Overlay>
  </Container>
)

export default React.memo<Props>(Banner)
