import React from 'react'
import styled from 'styled-components'
import { WHITE } from '../../assets/colors'
import Button, { Size as ButtonSize } from '../../components/Button'

import bannerImg from '../../assets/images/gevel.jpeg'

type ImageProps = {
  img: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: 400px;
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
  width: calc(100% + 40px);

  @media (min-width: 769px) {
    min-height: 500px;
    max-height: 500px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
  }

  @media (min-width: 1025px) {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
  }

  @media (min-width: 1441px) {
    margin-left: -120px;
    margin-right: -120px;
    width: calc(100% + 240px);
  }
`

const Image = styled.div<ImageProps>`
  background-image: url('${({ img }) => img}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  width: 100%;

  @media (min-width: 769px) {
    min-height: 500px;
    max-height: 500px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1025px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: 1441px) {
    padding-left: 120px;
    padding-right: 120px;
  }
`

const Caligraphy = styled.h1`
  color: ${WHITE};
  font-family: 'Allura', cursive;
  font-size: 4rem;
  margin-bottom: 20px;
  opacity: 0.4;
`

const Title = styled.h2`
  color: ${WHITE};
  font-size: 6rem;
  font-weight: 700;
  line-height: 6rem;
  margin-top: 15px;
`

const Subtitle = styled.h2`
  color: ${WHITE};
  font-size: 5rem;
  font-weight: 700;
  line-height: 5rem;
`

const Banner: React.FC = () => {
  return (
    <Container>
      <Image img={bannerImg} />
      <Overlay>
        <Caligraphy>Kapsalon in Wichelen</Caligraphy>
        <Subtitle>Welkom bij</Subtitle>
        <Title className="margin-b-25">Coifflo</Title>
        <div>
          <Button size={ButtonSize.small}>Maak uw afspraak</Button>
        </div>
      </Overlay>
    </Container>
  )
}

export default React.memo(Banner)
