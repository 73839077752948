import React from 'react'
import styled from 'styled-components'
import { PRIMARY, WHITE } from '../../assets/colors'

type Props = {
  className?: string
  title: string
  icon: string
  info: string
  action?: string
  onClick?: (e?: any) => void
}

const Container = styled.div`
  align-items: center;
  display: flex;
`

const IconContainer = styled.div`
  background-color: ${PRIMARY};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-right: 15px;
  min-width: 40px;
  max-width: 40px;
`

const Icon = styled.i`
  color: ${WHITE};
  font-size: 2rem;
`

const Title = styled.div`
  font-size: 1.4rem;
`

const Info = styled.div`
  cursor: pointer;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 4px;
`

const ContactInfoItem: React.FC<Props> = ({ className, title, info, icon, action, onClick }) => (
  <Container className={className}>
    <IconContainer>
      <Icon className={icon} />
    </IconContainer>
    <div>
      <Title>{title}:</Title>
      {action ? (
        <a href={action}>
          <Info>{info}</Info>
        </a>
      ) : (
        <Info onClick={onClick}>{info}</Info>
      )}
    </div>
  </Container>
)

export default React.memo<Props>(ContactInfoItem)
