import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import Me from '../About/Me'
import Banner from './Banner'
import Services from './Services'
import { Wrapper } from '../Contact'
import Reviews from '../../components/Reviews'
import ScrollToTop from '../../components/ScrollToTop'
import { TwoFrames, FrameOne, FrameTwo } from '../About'
/** ASSETS */
import aboutFirstImg from '../../assets/images/about_first.jpg'
import aboutSecImg from '../../assets/images/about_second.jpeg'

const Container = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const Home: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 540px)').matches)
  const [isTablet, setIsTablet] = useState(window.matchMedia('(min-width: 769px)').matches)
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 1076px)').matches)

  useEffect(() => {
    window.matchMedia('(max-width: 540px)').addEventListener('change', (e: any) => setIsMobile(e.matches))
    window.matchMedia('(min-width: 769px)').addEventListener('change', (e: any) => setIsTablet(e.matches))
    window.matchMedia('(min-width: 1076px)').addEventListener('change', (e: any) => setIsDesktop(e.matches))
  }, [])

  return (
    <>
      <ScrollToTop />
      <Container>
        <Banner />
        <Wrapper isDesktop={isDesktop} className="margin-b-20">
          <TwoFrames oneFrame={isMobile}>
            <FrameTwo img={aboutFirstImg} />
            {!isMobile && <FrameOne img={aboutSecImg} oneFrame={isMobile} />}
          </TwoFrames>
          <Me isDesktop={isDesktop} />
        </Wrapper>
        <Services isTablet={isTablet} />
        <Reviews />
      </Container>
    </>
  )
}

export default Home
