import styled from 'styled-components'
import React, { useEffect, useRef } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock'
import Menu from './Menu'
import Image from './Image'
import Navigation from './Navigation'
import Button, { Size as ButtonSize } from './Button'
import { WHITE, PRIMARY } from '../assets/colors'
/** ASSETS */
import coiffloLogo from '../assets/images/logo.png'

type Props = {
  isDesktop: boolean
  isMobile: boolean
  openMenu: boolean
  onToggleOpenMenu: () => void
}

const Container = styled.header`
  align-items: center;
  background-color: ${WHITE};
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  width: 100%;
`

const Icon = styled.i`
  color: ${PRIMARY};
  cursor: pointer;
  font-size: 28px;
  text-align: center;
  width: 38px;
`

const Logo = styled(Image)`
  margin-right: 50px;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;

  & > button {
    margin-right: 30px;
  }
`

const Header: React.FC<Props> = ({ isMobile, isDesktop, openMenu, onToggleOpenMenu }) => {
  const targetRef = useRef()

  const renderMenuButton = () => {
    return <Icon className={`fas ${openMenu ? 'fa-times' : 'fa-bars'}`} onClick={onToggleOpenMenu}></Icon>
  }

  useEffect(() => {
    if (targetRef?.current) {
      if (openMenu) disableBodyScroll(targetRef.current)
      if (!openMenu) enableBodyScroll(targetRef.current)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [openMenu])

  return (
    <>
      <Container>
        <Logo img={coiffloLogo} height={70} />
        {isDesktop ? (
          <Navigation />
        ) : (
          <Wrapper>
            {!isMobile && <Button size={ButtonSize.small}>Boek online</Button>}
            {renderMenuButton()}
          </Wrapper>
        )}
      </Container>
      {openMenu && <Menu innerRef={targetRef} onToggleOpenMenu={onToggleOpenMenu} />}
    </>
  )
}

export default React.memo<Props>(Header)
