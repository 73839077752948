import React from 'react'
import styled from 'styled-components'
import Image from '../Image'
import Contact from './Contact'
import WorkingHours from './WorkingHours'
import { BLACK, GREY, WHITE, WHITE_ALPHA_85 } from '../../assets/colors'
/** ASSETS */
import coiffloLogo from '../../assets/images/logo.png'
import partnerLogo from '../../assets/images/partner-logo.png'
import contactData from '../../assets/data/contact.json'

type Props = {
  isMobile: boolean
  isTablet: boolean
}

const Container = styled.footer`
  background-color: ${BLACK};
  cursor: default;
  display: flex;
  flex-direction: column;
  padding: 65px 0 30px 0;
  width: 100%;
`

const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
  }

  @media (min-width: 1025px) {
    padding: 0 60px;
  }

  @media (min-width: 1441px) {
    padding: 0 120px;
  }
`

export const Section = styled.section`
  color: ${WHITE};
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;
  flex: 0;
  line-height: 2rem;
  text-align: center;
  width: 300px;

  @media (min-width: 769px) {
    flex: 0 0 200px;
    text-align: left;
  }

  @media (min-width: 1025px) {
    flex: 0 0 300px;
  }

  & h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 30px;

    @media (min-width: 769px) {
      margin-bottom: 40px;
    }
  }
`

const Seperator = styled.hr`
  background-color: ${GREY};
  border: none;
  height: 1px;
  margin: 40px 0 30px 0;
  width: 100%;
`

const Bottom = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 0 40px;
  }

  @media (min-width: 1025px) {
    padding: 0 60px;
  }

  @media (min-width: 1441px) {
    padding: 0 120px;
  }
`

const LogoSection = styled(Section)`
  margin-bottom: 60px;

  @media (min-width: 769px) {
    margin-bottom: 0;
  }

  & h2 {
    font-weight: 500;
    font-size: 1.4rem;
    margin: 20px 0;

    @media (min-width: 769px) {
      font-size: 1.6rem;
      margin: 30px 0;
      text-align: center;
    }
  }
`

const Rights = styled(Section)`
  display: block;
  font-size: 1.4rem;
  margin-bottom: 10px;
  text-align: center;

  @media (min-width: 769px) {
    font-size: 1.6rem;
    text-align: right;
  }
`

const Socials = styled(Section)`
  display: block;
  text-align: center;
`

const VAT = styled(Section)`
  display: block;
  font-size: 1.4rem;
  margin-bottom: 10px;
  text-align: center;

  @media (min-width: 769px) {
    font-size: 1.6rem;
    text-align: left;
  }
`

const SocialIcon = styled.i`
  color: ${WHITE};
  cursor: pointer;
  font-size: 2.4rem;
  text-align: center;
  transition: color 100ms linear;
  width: 24px;

  &:hover {
    color: ${WHITE_ALPHA_85};
  }
`

const WorkingHoursSection = styled(Section)`
  margin-bottom: 60px;

  @media (min-width: 769px) {
    margin-bottom: 0;
  }
`

const Footer: React.FC<Props> = ({ isMobile, isTablet }) => {
  const onOpenFacebook = () => {
    window.open('https://www.facebook.com/coifflo.hairdesign', '_blank')
  }

  const onOpenInstagram = () => {
    window.open('https://www.instagram.com/coifflo/', '_blank')
  }

  const onCopyToClipboard = (e: any) => {
    const el = e.target
    navigator.clipboard.writeText(el.innerText)
  }

  return (
    <Container>
      <Main>
        {isMobile ? (
          <>
            <LogoSection>
              <Image img={coiffloLogo} height={isMobile ? 60 : 80} />
              <h2>in samenwerking met</h2>
              <Image img={partnerLogo} height={isMobile ? 40 : 50} />
            </LogoSection>
            <WorkingHoursSection>
              <WorkingHours isTablet={isTablet} />
            </WorkingHoursSection>
            <Contact />
          </>
        ) : (
          <>
            <WorkingHoursSection>
              <WorkingHours isTablet={isTablet} />
            </WorkingHoursSection>
            <LogoSection>
              <Image img={coiffloLogo} height={80} />
              <h2>in samenwerking met</h2>
              <Image img={partnerLogo} height={50} />
            </LogoSection>
            <Contact />
          </>
        )}
      </Main>
      <Seperator />
      <Bottom>
        {isMobile ? (
          <>
            <Rights>© {new Date().getFullYear()} Coifflo</Rights>
            <VAT onClick={onCopyToClipboard}>
              BTW: <strong>{contactData.vat}</strong>
            </VAT>
            <Socials>
              <SocialIcon
                className={`fab fa-facebook ${isMobile ? 'margin-r-15' : 'margin-r-30'}`}
                onClick={onOpenFacebook}
              />
              <SocialIcon className="fab fa-instagram" onClick={onOpenInstagram} />
            </Socials>
          </>
        ) : (
          <>
            <VAT onClick={onCopyToClipboard}>
              BTW: <strong>{contactData.vat}</strong>
            </VAT>
            <Socials>
              <SocialIcon
                className={`fab fa-facebook ${isMobile ? 'margin-r-15' : 'margin-r-30'}`}
                onClick={onOpenFacebook}
              />
              <SocialIcon className="fab fa-instagram" onClick={onOpenInstagram} />
            </Socials>
            <Rights>© {new Date().getFullYear()} Coifflo</Rights>
          </>
        )}
      </Bottom>
    </Container>
  )
}

export default React.memo<Props>(Footer)
