import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { BLACK } from '../assets/colors'

type Props = {
  openMenu: boolean
}

type ContainerProps = {
  openMenu: boolean
}

const Container = styled.div<ContainerProps>`
  color: ${BLACK};
  display: flex;
  ${({ openMenu }) => openMenu && 'filter: blur(1px);'}
  height: calc(100% - 100px);
  width: 100%;
`

const Content: React.FC<Props> = ({ openMenu }) => (
  <Container openMenu={openMenu}>
    <Outlet />
  </Container>
)

export default React.memo<Props>(Content)
