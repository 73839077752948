import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner'
import ContactInfoItem from './ContactInfoItem'
import ImageFrame from '../../components/ImageFrame'
import ScrollToTop from '../../components/ScrollToTop'
import ContentTitle from '../../components/ContentTitle'
import WorkingHours, { Usage } from '../../components/footer/WorkingHours'
import { PRIMARY, PRIMARY_MINUS_70 } from '../../assets/colors'
/** ASSETS */
import bannerImg from '../../assets/images/banner.jpeg'
import locationImg from '../../assets/images/location.png'
import interieurImg from '../../assets/images/salon-interieur.jpeg'
import exterieurImg from '../../assets/images/contact.jpeg'
import contactData from '../../assets/data/contact.json'

export type ResponsiveProps = {
  isDesktop: boolean
}

export type ImageProps = {
  img: string
}

const Container = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Wrapper = styled.div<ResponsiveProps>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  gap: ${({ isDesktop }) => (isDesktop ? '80px' : '40px')};
  margin-top: ${({ isDesktop }) => (isDesktop ? '40px' : '30px')};

  & > div {
    flex: 1;
  }
`

export const WrapperReverse = styled(Wrapper)`
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column-reverse')};
  margin-bottom: ${({ isDesktop }) => (isDesktop ? '40px' : '30px')};
`

const WorkingHoursSection = styled.div<ResponsiveProps>`
  ${({ isDesktop }) => isDesktop && 'align-self: center'};
  font-size: 2rem;
  line-height: 2.6rem;
`

const Location = styled.div<ResponsiveProps>`
  cursor: pointer;
  display: flex;
  min-height: ${({ isDesktop }) => (isDesktop ? '400px' : '200px')};
  max-height: ${({ isDesktop }) => (isDesktop ? '400px' : '200px')};
  margin-left: -20px;
  margin-right: -20px;
  margin-top: ${({ isDesktop }) => (isDesktop ? '40px' : '30px')};
  position: relative;
  width: calc(100% + 40px);

  @media (min-width: 769px) {
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
  }

  @media (min-width: 1025px) {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
  }

  @media (min-width: 1441px) {
    margin-left: -120px;
    margin-right: -120px;
    width: calc(100% + 240px);
  }
`

const LocationImage = styled.div<ImageProps>`
  background-image: url('${({ img }) => img}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
`

const Overlay = styled.div`
  align-items: center;
  background-color: ${PRIMARY};
  display: flex;
  justify-content: center;
  height: 100%;
  opacity: 0.4;
  position: absolute;
  width: 100%;
`

const ContactSection = styled.div<ResponsiveProps>`
  ${({ isDesktop }) => isDesktop && 'align-self: center'};
`

const FollowUs = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-right: 15px;
`

const Socials = styled.div`
  align-items: center;
  display: flex;
  margin-top: 30px;
`

const SocialIcon = styled.i`
  color: ${PRIMARY};
  cursor: pointer;
  font-size: 2.4rem;
  text-align: center;
  transition: color 100ms linear;
  width: 24px;

  &:hover {
    color: ${PRIMARY_MINUS_70};
  }
`

const Contact: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 1076px)').matches)

  const onOpenFacebook = () => {
    window.open('https://www.facebook.com/coifflo.hairdesign', '_blank')
  }

  const onOpenInstagram = () => {
    window.open('https://www.instagram.com/coifflo/', '_blank')
  }

  const onNavigateToGoogleMaps = () => {
    window.open(
      'https://www.google.com/maps/dir//Coifflo,+Margote+20,+9260+Wichelen/@51.0095393,3.9708986,17.18z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c3993ef69a9f77:0x676e1af8d69a55f!2m2!1d3.9707315!2d51.0079701!3e0',
      '_blank'
    )
  }

  const onCopyToClipboard = (e: any) => {
    const el = e.target
    navigator.clipboard.writeText(el.innerText)
  }

  useEffect(() => {
    window.matchMedia('(min-width: 1076px)').addEventListener('change', (e: any) => setIsDesktop(e.matches))
  }, [])

  return (
    <>
      <ScrollToTop />
      <Container>
        <Banner title="Contact" img={bannerImg} />
        <Wrapper isDesktop={isDesktop}>
          <WorkingHoursSection isDesktop={isDesktop}>
            <WorkingHours usage={Usage.content} />
          </WorkingHoursSection>
          <ImageFrame img={interieurImg} />
        </Wrapper>
        <Location isDesktop={isDesktop} onClick={onNavigateToGoogleMaps}>
          <LocationImage img={locationImg} />
          <Overlay />
        </Location>
        <WrapperReverse isDesktop={isDesktop}>
          <ImageFrame img={exterieurImg} />
          <ContactSection isDesktop={isDesktop}>
            <ContentTitle className="margin-b-25" title="Neem contact op" />
            <ContactInfoItem
              className="margin-b-15"
              title="GSM"
              info={contactData.tel}
              icon="fas fa-phone-alt"
              action={`tel:${contactData.tel}`}
            />
            <ContactInfoItem
              className="margin-b-15"
              title="Email"
              info={contactData.email}
              icon="fas fa-envelope"
              action={`mailto:${contactData.email}`}
            />
            <ContactInfoItem
              className="margin-b-15"
              title="Adres"
              info={`${contactData.addressStreet}, ${contactData.addressCity}`}
              icon="fas fa-map-marker-alt"
              onClick={onNavigateToGoogleMaps}
            />
            <ContactInfoItem title="BTW" info={contactData.vat} icon="fas fa-percentage" onClick={onCopyToClipboard} />
            <Socials>
              <FollowUs>Blijf up-to-date:</FollowUs>
              <SocialIcon className="fab fa-facebook margin-r-15" onClick={onOpenFacebook} />
              <SocialIcon className="fab fa-instagram" onClick={onOpenInstagram} />
            </Socials>
          </ContactSection>
        </WrapperReverse>
      </Container>
    </>
  )
}

export default Contact
