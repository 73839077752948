import React from 'react'
import styled from 'styled-components'
import Carousel from './Carousel'
import { ImageProps } from '../pages/Contact'
import { PRIMARY, WHITE, WHITE_ALPHA_85 } from '../assets/colors'
/** ASSETS */
import reviewBgImg from '../assets/images/review-background.jpg'

// background-image: url('${({ img }) => img}');
// background-position: center;
// background-repeat: no-repeat;
// background-size: cover;

const Container = styled.div<ImageProps>`
  margin: 0 -20px;
  position: relative;
  width: calc(100% + 40px);

  @media (min-width: 769px) {
    margin: 0 -40px;
    width: calc(100% + 80px);
  }

  @media (min-width: 1025px) {
    margin: 0 -60px;
    width: calc(100% + 120px);
  }

  @media (min-width: 1441px) {
    margin: 0 -120px;
    width: calc(100% + 240px);
  }
`

const Content = styled.div`
  padding: 40px 20px;
  width: 100%;

  @media (min-width: 769px) {
    padding: 40px 80px;
  }

  @media (min-width: 1025px) {
    margin: auto;
    width: 800px;
  }
`

const Overlay = styled.div`
  background-color: ${WHITE_ALPHA_85};
  height: 100%;
  position: absolute;
  width: 100%;
`

const QuoteWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
`

const Quote = styled.div`
  align-items: center;
  background-color: ${PRIMARY};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  max-height: 70px;
  min-height: 70px;
  max-width: 70px;
  min-width: 70px;
`

const QuoteIcon = styled.i`
  color: ${WHITE};
  font-size: 3rem;
`

const Reviews: React.FC = () => (
  <Container img={reviewBgImg}>
    <Overlay />
    <Content>
      <QuoteWrapper>
        <Quote>
          <QuoteIcon className="fas fa-quote-left" />
        </Quote>
      </QuoteWrapper>
      <Carousel />
    </Content>
  </Container>
)

export default Reviews
