import React from 'react'
import { ContentWrapper } from './index'
import ContentTitle from '../../components/ContentTitle'

type Props = {
  isDesktop: boolean
}

const Me: React.FC<Props> = ({ isDesktop }) => (
  <ContentWrapper isDesktop={isDesktop}>
    <ContentTitle title="Uw kapster" subtitle="Florence Huylebroeck" />
    <div>
      <span className="margin-b-15">
        De voorbije jaren deed ik al ruime ervaring op in verschillende salons én daarna begon ik als zelfstandig
        kapster aan huis. Het was altijd mijn droom om een eigen zaak op te starten dus werd het tijd voor een nieuwe
        uitdaging, mijn passie keer op keer uitoefenen in mijn eigen kapsalon. <br />
        Ik ben een gemotiveerde kapster die er van geniet om haar creativiteit te uiten. Het is belangrijk dat mijn
        klanten gelukkig de zaak verlaten. Om dit te kunnen bereiken zorg ik altijd voor een persoonlijke aanpak. We
        gaan samen op zoek naar de perfecte coupe of het mooiste kleurtje.
      </span>
      <span>
        Om up to date te blijven volg ik regelmatig bijscholing, zo blijf ik steeds op de hoogte van de nieuwste trends.
        Ik kijk er enorm hard naar uit jullie te mogen verzorgen in mijn coronaproof salon!
      </span>
    </div>
  </ContentWrapper>
)

export default React.memo<Props>(Me)
