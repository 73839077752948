import React from 'react'
import styled from 'styled-components'

type Props = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  className?: string
}

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.4rem;
`

const Subtitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-top: 5px;
`

const ContentTitle: React.FC<Props> = ({ title, subtitle, className }) => (
  <div className={`margin-b-20 ${className}`}>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </div>
)

export default React.memo<Props>(ContentTitle)
