const WHITE = '#FFF'
const WHITE_ALPHA_85 = 'rgba(255, 255, 255, 0.85)'
const BLACK = '#474747'
const GREY = '#6C6C6C'
const PRIMARY = '#A1BBA3'
const PRIMARY_MINUS_20 = '#A1BBA333'
const PRIMARY_MINUS_70 = '#A1BBA3B3'
const BACKGROUND_PRIMARY = '#F6F8F6'

export { WHITE, WHITE_ALPHA_85, BLACK, GREY, PRIMARY, PRIMARY_MINUS_20, PRIMARY_MINUS_70, BACKGROUND_PRIMARY }
