import React from 'react'
import styled from 'styled-components'

type Props = {
  feed: any
  className?: string
  itemWidth: number
}

type ContainerProps = {
  itemWidth: number
}

const Container = styled.div<ContainerProps>`
  flex-grow: 1;
  height: ${({ itemWidth }) => `${itemWidth}px`};
  width: ${({ itemWidth }) => `${itemWidth}px`};

  &:hover {
    cursor: pointer;
  }

  > img {
    object-fit: cover;
  }
`

const ContentTitle: React.FC<Props> = ({ feed, className, itemWidth }) => {
  const { id, caption, media_url, permalink } = feed

  const onOpenInstagramPost = (url: string) => () => {
    window.open(url, '_blank')
  }

  return (
    <Container id={id} key={id} className={className} itemWidth={itemWidth} onClick={onOpenInstagramPost(permalink)}>
      <img width="100%" height="100%" src={media_url} alt={caption} />
    </Container>
  )
}

export default React.memo<Props>(ContentTitle)
