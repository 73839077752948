import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  img: string
}

const Frame = styled.div`
  border-radius: 6px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 10px;
`

const Image = styled.img`
  height: auto;
  width: 100%;
`

const ImageFrame: React.FC<Props> = ({ className, img }) => (
  <Frame className={className}>
    <Image src={img} />
  </Frame>
)

export default React.memo<Props>(ImageFrame)
