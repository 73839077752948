import React from 'react'
import styled from 'styled-components'
// import { useNavigate } from 'react-router-dom'
import ContentTitle from '../../components/ContentTitle'
import { BACKGROUND_PRIMARY } from '../../assets/colors'
// import Button, { Size as ButtonSize } from '../../components/Button'
import ImageFrame from '../../components/ImageFrame'

import serviceCutImg from '../../assets/images/service-cut.jpg'
import serviceStylingImg from '../../assets/images/service-styling.jpg'
import serviceColorImg from '../../assets/images/service-color.jpg'

type Props = {
  isTablet: boolean
}

type ContentProps = {
  isTablet: boolean
}

const Container = styled.div`
  background-color: ${BACKGROUND_PRIMARY};
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 20px;
  width: calc(100% + 40px);

  @media (min-width: 769px) {
    margin-left: -40px;
    margin-right: -40px;
    padding: 40px 40px;
    width: calc(100% + 80px);
  }

  @media (min-width: 1025px) {
    margin-left: -60px;
    margin-right: -60px;
    padding: 40px 60px;
    width: calc(100% + 120px);
  }

  @media (min-width: 1441px) {
    margin-left: -120px;
    margin-right: -120px;
    padding: 40px 120px;
    width: calc(100% + 240px);
  }
`

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: 30px;
  text-align: center;
`

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${({ isTablet }) => (isTablet ? 'row' : 'column')};
  gap: 40px;

  & > div {
    flex: ${({ isTablet }) => (isTablet ? '0 1 calc(100% / 3)' : '0 1 100%')};
  }
`

const ServiceTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-top: 15px;
  text-align: center;
`

const Text = styled.div`
  margin-top: 5px;
  padding: 0 20px;
  text-align: center;
`

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: center;
// `

const Services: React.FC<Props> = ({ isTablet }) => {
  // const navigate = useNavigate()

  // const onNavigateToPricing = () => navigate('/pricing')

  return (
    <Container>
      <StyledContentTitle title="Wat bieden wij u aan" />
      <Content isTablet={isTablet}>
        <div>
          <ImageFrame img={serviceCutImg} />
          <ServiceTitle>Knippen</ServiceTitle>
          <Text>Wij knippen zowel vrouwen, mannen én kinderen.</Text>
        </div>
        <div>
          <ImageFrame img={serviceStylingImg} />
          <ServiceTitle>Stylen</ServiceTitle>
          <Text>
            Wij stylen uw haar door middel van verschillende technieken. Beachwaves, brushings, opsteekkapsels, ...
            <br />
            Voor ieder wat wils!
          </Text>
        </div>
        <div>
          <ImageFrame img={serviceColorImg} />
          <ServiceTitle>Kleuren</ServiceTitle>
          <Text>
            Indien u nood heeft aan een nieuwe frisse kleur of uw grijze haren wil maskeren. Van highlights, lowlights,
            ombre's tot het bijwerken van een uitgroei.
          </Text>
        </div>
      </Content>
      {/* <ButtonWrapper className="margin-t-30">
        <Button size={ButtonSize.small} onClick={onNavigateToPricing}>
          Bekijk prijslijst
        </Button>
      </ButtonWrapper> */}
    </Container>
  )
}

export default React.memo<Props>(Services)
