import React from 'react'
import styled from 'styled-components'
import { Usage } from './WorkingHours'
import { BLACK, WHITE } from '../../assets/colors'

type Props = {
  day: string
  openHours: string
  usage?: Usage
}

type LineProps = {
  usage: Usage
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Line = styled.div<LineProps>`
  background-color: ${({ usage }) => (usage === Usage.footer ? WHITE : BLACK)};
  flex: 1;
  height: 1px;
  margin: 0 10px;
  opacity: 0.2;
`

const WorkingDay: React.FC<Props> = ({ day, openHours, usage = Usage.footer }) => (
  <Container>
    <div>{day}</div>
    <Line usage={usage} />
    <div>{openHours}</div>
  </Container>
)

export default WorkingDay
